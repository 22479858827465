import React from "react";
import styled, { css } from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Text, Box } from "@cyberalarm/ui";
import { base64ToBlob } from "@cyberalarm/common";
import Linkify from "linkify-react";

export const SectionLabel = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const SectionDescription = styled(Text)`
  white-space: pre-wrap;
  font-size: 16px;
  a {
    color: #000000;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

const StyledImage = styled.img<{ clickable: boolean }>`
  padding: 4px;
  border: 1px solid #d0d0d0;
  ${(p) =>
    p.clickable &&
    css`
      cursor: pointer;
    `}
`;

export const SectionRoot = styled(Box)`
  border-top: 1px solid ${themeGet("colors.grays.0")};
`;

export const SectionItem = ({
  text,
  label,
}: {
  text: string | null;
  label: MessageDescriptor;
}) => {
  const intl = useIntl();
  return (
    <Box marginBottom={3}>
      <SectionLabel mb="20px">{intl.formatMessage(label)}</SectionLabel>
      <Linkify options={{ target: { url: "_blank" } }}>
        <SectionDescription>{text}</SectionDescription>
      </Linkify>
    </Box>
  );
};

export const ImagePreview = ({
  label,
  base64String,
  height = "72px",
  width = "72px",
  openFullImage = false,
}: {
  label: MessageDescriptor;
  base64String: string;
  height?: string;
  width?: string;
  openFullImage?: boolean;
}) => {
  const intl = useIntl();
  const handleClick = () => {
    if (openFullImage) {
      const blob = base64ToBlob({ base64String });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
      URL.revokeObjectURL(blobUrl);
    }
  };

  return (
    <Box flexDirection="row" marginY="8px">
      <Text marginRight={3} marginY="auto">
        {intl.formatMessage(label)}
      </Text>
      <StyledImage
        src={base64String}
        height={height}
        width={width}
        clickable={openFullImage}
        onClick={handleClick}
      />
    </Box>
  );
};
